import React from "react";
import IndexPage from "./index"

const IndexPagePO = () => (
  <IndexPage i18n="po"/>
);

export default IndexPagePO;


